import './i18n';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import Cookies from 'js-cookie';


export const Navigation = (props) => {
  let imgSrc = "";
  if(props.data !== undefined){
    imgSrc = props.data.logo !== undefined ? props.data.logo : "";
    }
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const getlang = getCookie('skf-language');
    const [language, setLanguage] = useState(getlang);

    useEffect(() => {
        let val = getCookie('skf-language');
        setLanguage(val);
        i18n.changeLanguage(val);
        Cookies.remove('skf-language', { path: '/' });
        Cookies.set('skf-language', val, { path: '/' });
    }, []);

    const Changelanguage = (event) => {
        let val = event.target.value;
        setLanguage(val);
        i18n.changeLanguage(val);
        Cookies.remove('skf-language', { path: '/' });
        Cookies.set('skf-language', val, { path: '/' });
    };
   

    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

  return (
    
    
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          
          <a className='navbar-brand page-scroll'>
            {
            imgSrc === "" ?
            <img src="img/logo.png" className="page-logo" alt="" />
            :
            <img src={imgSrc} className="page-logo" alt="" />
          }
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
                <a href="https://www.skyfrog.net/home/privacy-policy/" target="_blank">
                   Privacy Policy
                </a>
            </li>
            <li>
              <a href='https://www.skyfrog.net/app/Login/' target="_blank">
                 {t('Customer Login')}
              </a>
            </li>
            <li>
              <a href='https://www.skyfrog.net/home/' target="_blank">
                 {t('Skyfrog')}
              </a>
            </li>
            <li class="nav-item custom-select fa">&#xf57d;
                <select id="change_lang" onChange={Changelanguage} value={language}>                   
                    <option value="en">English</option>
                    <option value="th"> ภาษาไทย</option>
                </select>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
