export const Footer = (props) => {
    return (
        <div id='footer'>
            <div className='container text-center'>
            <p>
            {props.data ? props.data.footerText : ''} 
            
            </p>
            
            
            </div>
        </div>
    );
};