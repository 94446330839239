import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

var getlang = getCookie('skf-language');
var language = "en";
if (getlang != '' && getlang != undefined) {
    language = getlang;
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: language ? language :'en',
        fallbackLng: language ? language : 'en',
        interpolation: {
            escapeValue: false
        }
    });

function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}
export default i18n;