import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import Axios from "axios";
import { Track } from "./components/track";
import { Contact } from "./components/contact";
import { Footer } from "./components/footer";
import { Result } from "./components/result";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// const sampledata = require('../config.json');
import Cookies from 'js-cookie';
import './components/i18n';
import { useTranslation } from 'react-i18next';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const MySwal = withReactContent(Swal)
const App = () => {
  // console.log(sampledata.REACT_APP_API_URL);
  const [landingPageData, setLandingPageData] = useState({});
  const [previewData, setPreviewData] = useState({});
  const [activePage, setActivePage] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const preview = params.get('Preview');
  const lange = params.get('Lange') ? params.get('Lange') : params.get('lange');

    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }
    const getlang = getCookie('skf-language');

  useEffect(() => {


    let url = window.location.href;
    let customerId = url.substring(url.lastIndexOf('/')).split('-')[0].replace('/','').split('#')[0].split('?')[0]; 

    let data = { CustomerId: customerId };
      console.log(data);
      if (getlang != null) {
          Cookies.remove('skf-language', { path: '/' });
      }
      if (lange != null && lange != '') {

          if (lange == "en" || lange == "th") {
              Cookies.set('skf-language', lange, { path: '/' });
          } else {
              Cookies.set('skf-language', "th", { path: '/' });
          }

      } else {
          Cookies.set('skf-language', "th", { path: '/' });
      }


    //var tmpurl = new URL(url);
    //var lang = tmpurl.searchParams.get("lange");
    //  alert(lang);
    //Cookies.remove('skf-language', { path: '/' });
    //Cookies.set('skf-language', lang, { path: '/' });

    if(preview === 'true'){
      Axios.post(`${process.env.REACT_APP_API_URL}/Tracking/PreviewPage`, data).then(
        (response) => {
          setActivePage(response.data.status)
          
          if(response.data.status)
            setPreviewData(response.data.data);
          // else
          //   MySwal.fire({
          //     title: <b>{response.data.message}</b>,
          //     html: <div></div>,
          //     icon: 'error',
          //     confirmButtonColor: 'rgb(31 162 222)',
          //   });

        }
      ).catch((err) => {
        alert('error:'+err)
      });
    } else {
      Axios.post(`${process.env.REACT_APP_API_URL}/Tracking/LandingPage`, data).then(
        (response) => {
          setActivePage(response.data.status)
          if(response.data.status)
            setLandingPageData(response.data.data);
          // else{
          //   MySwal.fire({
          //     title: <b>{response.data.message}</b>,
          //     html: <div></div>,
          //     icon: 'error',
          //     confirmButtonColor: 'rgb(31 162 222)',
          //   });
          // }
        }
      ).catch((err) => {
        // MySwal.fire({
        //   title: <b>{err}</b>,
        //   html: <div></div>,
        //   icon: 'error',
        //   confirmButtonColor: 'rgb(31 162 222)',
        // });
      });
    }
  }, []);

  let ACTIVEPAGE = <div>
                    
                    {
                      preview === "true" ?
                      <div className="preview">
                        <Navigation data={previewData.pageHeader}/>
                        <Header />
                        <Track data={previewData.pageHeader} />
                        <Result data={previewData.result} />
                        <Contact data={previewData.contact} />
                        <Footer data={previewData.contact}/>
                      </div>
                      
                      :
                      <div>
                        <Navigation data={landingPageData.pageHeader}/>
                        <Header />
                        <Track data={landingPageData.pageHeader} />
                        <Contact data={landingPageData.contact} />
                        <Footer data={landingPageData.contact}/>
                      </div>
                    }
                    
                  </div>;
  return (
    activePage ? ACTIVEPAGE : <div></div>
  );
};

export default App;
