export const Header = (props) => {
    return (<header id='header' className="header-container">
    <div className='intro'>
      <div className='overlay'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-md-offset-2 intro-text'>
                <img className="header-banner" src="img/hero-img.png"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
    );
}
