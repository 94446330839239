import { useTranslation } from 'react-i18next';

export const Result = (props) => {
    const { t } = useTranslation();
    let {data} = props;
    const ReplaceStatusDiv = (track) => {
        return <div className="row container result-wizard">
                    <div className="wizard-steps">
                        {/* <div className="col-md-2"></div> */}
                        <div className="">
                            <div className={track.trackStatusList[0].statusVal === true ? "status wizard-step1-inner-success" : "status wizard-step1-inner"}>
                                <div className="wizard-label">
                                    <i className="fas fa-truck-loading track-icon"></i>
                                </div>
                            </div>
                            <h5 className={track.trackStatusList[0].statusVal === true ? "wizard-title" : "wizard-title2"}>{track.trackStatusList[0].statusName}</h5>
                        </div>
                        
                        <span className={track.trackStatusList[1].statusVal === true ? "w-a wizard-arrow-s" : "w-a wizard-arrow"}>
                            <i className="fa fa-chevron-right icon-5x"></i>
                        </span>
                        <div className="">
                            <div className={track.trackStatusList[1].statusVal === true ? "status wizard-step2-inner-success" : "status wizard-step2-inner"}>
                                <div className="wizard-label">
                                    <i className="fas fa-truck-moving track-icon"></i>
                                </div>
                            </div>
                            <h5 className={track.trackStatusList[1].statusVal === true ? "wizard-title" : "wizard-title2"}>{track.trackStatusList[1].statusName}</h5>
                        </div>
                        
                        <span className={track.trackStatusList[2].statusVal === true ? "w-a wizard-arrow-s" : "w-a wizard-arrow"}>
                            <i className="fa fa-chevron-right icon-5x"></i>
                        </span>
                        <div className="">
                            <div className={
                                track.trackStatusList[2].statusVal === true ? 
                                track.trackStatusList[2].statusCancel === false ? "status wizard-step3-inner-success" : "status wizard-step-inner-cancel"  
                                : 
                                "status wizard-step3-inner"
                                }>
                                <div className="wizard-label">
                                    <i className="fas fa-check-square track-icon"></i>
                                </div>
                            </div>
                            <h5 className={track.trackStatusList[2].statusVal === true ? "wizard-title" : "wizard-title2"}>{track.trackStatusList[2].statusName}</h5>
                        </div>
                        
                        {/* <div className="col-md-2"></div> */}
                    </div>
                </div>
    };

    const NotHaveTrackingDiv = () => {
        return <div className="row container result-wizard">
                    <div className="wizard-steps">
                        <div className="col-md-2"></div>
                        <div className=" col-md-2">
                            <div className="">
                                <div className="wizard-label">
                                    <i className=""></i>
                                </div>
                            </div>
                            <h5 className="wizard-title"></h5>
                        </div>
                        
                        <span className=" col-md-1">
                            <i className=""></i>
                        </span>
                        <div className="col-md-2">
                            <div className="status ">
                                <div className="wizard-label">
                                    <i className="fas fa-times track-icon-err"></i>
                                </div>
                            </div>
                            <h5 className="wizard-title err">{t('Result')}</h5>
                        </div>
                        
                        <span className=" col-md-1">
                            <i className=""></i>
                        </span>
                        <div className="col-md-2">
                            <div className="status ">
                                <div className="wizard-label">
                                    <i className=""></i>
                                </div>
                            </div>
                            <h5 className="wizard-title"></h5>
                        </div>
                        
                        <div className="col-md-2"></div>
                    </div>
                </div>
    };

    return (
        <div className="container div-result">
        {
            data !== undefined ? data.map((track,index)=>{
                const RD_DETAIL = !track.hasData ? 
                                    <div className="row"><label></label></div> 
                                    : 
                                    (<div><div className="row">
                                    <label>{t('From')} : {track.fromName ?? ''}</label>
                                    </div>
                                    <div className="row">
                                    <label>{t('To')} : {track.toName ?? ''}</label>
                                    </div></div>);
                
                const STATUS_DIV = !track.hasData ? NotHaveTrackingDiv() : ReplaceStatusDiv(track);

                const MILESTONE_DIV = !track.hasData ? '' : (<div className="row container">
                                            <div className="timeline timeline-5">
                                                <div className="timeline-items row">
                                                    {track.mileStoneList.map((milestone,index2)=>{
                                                    return <div className="timeline-item row" key={index2}>
                                                        <div className={milestone.status ? "timeline-media bg-light-primary-s" : "timeline-media bg-light-primary"}>
                                                            <span className="svg-icon-primary svg-icon-md">
                                                                <i className={milestone.status ? "fas fa-check-circle timeline-icon-s" : "fas fa-circle timeline-icon"}></i>
                                                            </span>
                                                        </div>
                                                        <div className={milestone.status ? "timeline-desc timeline-desc-light-primary-s" : "timeline-desc timeline-desc-light-primary"}>
                                                            <div className="font-weight-bolder text-primary m-name">{milestone.name}
                                                                <p className="font-weight-normal text-dark-50 pb-2 m-detail">{milestone.msDueDate}</p></div>
                                                            <p className="font-weight-normal text-dark-50 pb-2 m-detail">
                                                                {t('Date')} : {milestone.msDate}<br></br>
                                                                {t('Time')} : {milestone.msTime}<br></br>
                                                                <br></br>
                                                                {milestone.itemList.map((itemList, index3) => {
                                                                    return <div key={index3}>{itemList}<br></br></div>
                                                                })}
                                                                <div className="row col-md-12">
                                                                {(milestone.rejectDrescription ? <div className="div-left">หมายเหตุ : {milestone.rejectDrescription}</div> : "")}
                                                                    <div className="div-left div-sig">
                                                                        {
                                                                            (milestone.signatureShow && track.trackStatusList[2].statusVal) ? 
                                                                            milestone.signatureDisable ? <a className="btn btn-xs btn-disable" href={milestone.signatureImg} target="_blank" rel="noreferrer" disabled>{t('Signature')}</a> : <a className="btn btn-xs btn-success" href={milestone.signatureImg} target="_blank" rel="noreferrer" >{t('Signature')}</a>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                    {
                                                                        !milestone.fromTMilestone ? 
                                                                        <div className="div-right">
                                                                            {milestone.detailType === "location" ? <i class="fas fa-map-marker-alt"></i> : <i class="fas fa-truck"></i>} 
                                                                            {' '}{milestone.detail}
                                                                        </div>
                                                                        :
                                                                        <div className="div-right"></div>
                                                                    }
                                                                    
                                                                </div> 
                                                                
                                                                
                                                            </p>
                                                        </div>
                                                    </div>
                                                    })}
                                                    
                                                </div>
                                            </div>
                                        </div>);

                return <div className="container track-result" key={index}>
                    <div className="container">
                        <div className="row">
                            <label className="trackno-label">{t('Track No.')} : {track.trackNo}</label>
                        </div>
                        {RD_DETAIL}
                    </div>
                    {STATUS_DIV}
                    {MILESTONE_DIV}
                </div>
            }) : 
            <div className="start-result">
                
            </div>
        }
        </div>
        
        
    )
}