import { useState,useEffect } from "react";
import Axios from "axios";
import { Result } from "./result";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import useClipboard from 'react-hook-clipboard';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from '../SocialButton';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

export const Track = (props) => {
  const MySwal = withReactContent(Swal);
  const [clipboard, copyToClipboard] = useClipboard();
  const [disbleBtn,setDisbleBtn] = useState(false);

  const [trackNo, setTrackNo] = useState("");
  const [TrackResult, setTrackResult] = useState(undefined);
  const [clickFind,setClickFind] = useState(false);

    const shareUrl = window.location.href;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const preview = params.get('Preview');
  
  //const [shareUrl, setShareUrl] = useState(url);
    const [title, setTitle] = useState("Skyfrog Track & Trace");

    const { t } = useTranslation();

  useEffect(() => {
    const paramTrackNo = params.get('TrackNo');
    if(paramTrackNo !== null){
      setTrackNo(paramTrackNo);
      // FindTracking();
        let data = { CustomerId: customerId, TrackNo: paramTrackNo, Lang: getCookie('skf-language') };

      Axios.post(`${process.env.REACT_APP_API_URL}/tracking/bytrackno`, data).then(
        (response) => {
          if(response.data !== undefined){
              if (response.data.status) {
                  document.querySelector(".div-result").scrollIntoView({ behavior: "smooth" });
              setTrackResult(response.data.data);
            }else{
              MySwal.fire({
                title: <b>{response.data.message}</b>,
                html: <div></div>,
                icon: 'error',
                confirmButtonColor: 'rgb(31 162 222)',
                width: '500px'
              });
            }
            
          }
            
        }
      ).catch((err) => {
        MySwal.fire({
          title: <b>{err}</b>,
          html: <div></div>,
          icon: 'error',
          confirmButtonColor: 'rgb(31 162 222)',
        });
      }).finally(() => {
        setDisbleBtn(false);
      });
    }
  },[]);
  
  var url = window.location.href;
  let customerId = url.substring(url.lastIndexOf('/')).split('-')[0].replace('/','').split('#')[0].split('?')[0]; 

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      FindTracking();
    }
  }

  const GetTrackingURL = () => {
    let tmp = url.split('?');
    if(tmp.length === 1){
      let txt = url + "?TrackNo=" + trackNo;
      copyToClipboard(txt);
      return txt;
    } else {
      let txt = "";
      if(clickFind === true){
        txt = tmp[0] + "?TrackNo=" + trackNo;
      } else {
        txt = tmp[0] + "?" + tmp[1];
      }
      copyToClipboard(txt);
      return txt;
    }
  }

  const FindTracking = () => {
    setDisbleBtn(true);
    setClickFind(true);
    if(trackNo){
        let data = { CustomerId: customerId, TrackNo: trackNo, Lang: getCookie('skf-language')};

      Axios.post(`${process.env.REACT_APP_API_URL}/tracking/bytrackno`, data).then(
        (response) => {
          if(response.data !== undefined){
            if(response.data.status){
              setTrackResult(response.data.data);
              var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?TrackNo=' + trackNo;
                window.history.pushState({ path: newurl }, '', newurl);
            }else{
              MySwal.fire({
                title: <b>{response.data.message}</b>,
                html: <div></div>,
                icon: 'error',
                confirmButtonColor: 'rgb(31 162 222)',
                width: '500px'
              });
            }
            
          }
            
        }
      ).catch((err) => {
        MySwal.fire({
          title: <b>{err}</b>,
          html: <div></div>,
          icon: 'error',
          confirmButtonColor: 'rgb(31 162 222)',
        });
      }).finally(() => {
        setDisbleBtn(false);
      });
    } else {
      MySwal.fire({
        title: <b>{t('MessageBox')}</b>,
        html: <div></div>,
        icon: 'error',
        confirmButtonColor: 'rgb(31 162 222)',
      });
      setDisbleBtn(false);
    }
  };
    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }
  const TRACKING = <div id="track">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text img-responsive">
               <h2>{props.data ? (props.data.title1 == null ? t('Header') : props.data.title1) : t('Header')}</h2>
               <p className="title2">{props.data ? (props.data.title2 == null ? t('Title') : props.data.title2): t('Title')}</p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-10'>
            <div className='form-group'>
              <div class="input-group-append">
              <input
                type='text'
                id='trackno-input'
                name='trackno'
                className='form-control'
                placeholder=''
                required
                value={trackNo}
                onKeyDown={preview === 'true' ? null : handleKeyDown} 
                onChange={(event) => {
                  setTrackNo(event.target.value);
                  // setTrackResult(undefined);
                }}
              />
              
                
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <button 
              onClick={FindTracking} 
              disabled={preview === 'true' ? true : disbleBtn} 
              className={disbleBtn ? "form-control btn-find-tacking-d" : "form-control btn-find-tacking"}
              >{t('Btntrack')}</button>
          </div>
        </div>
        <div className="container row">

        
          <label className="input-remark">{t('Remark')}</label>
              <p className='help-block text-danger'></p>
        </div>
      </div>
      {TrackResult ? 
          <div className="container">
              <div className="row">
                  <button className="btn btn-primary btn-xs" onClick={GetTrackingURL}><i class="far fa-copy copy-cb"></i> {t('Copy')}</button>
              </div>
              <div className="row shared-icon-div">
                  <div className="Demo__some-network div-left2">
                      <FacebookShareButton
                          url={shareUrl}
                          quote={title}
                          className="Demo__some-network__share-button"
                      >
                          <FacebookIcon size={32} round />
                      </FacebookShareButton>
                  </div>
                  <div className="Demo__some-network div-left2">
                      <TelegramShareButton
                          url={shareUrl}
                          title={title}
                          className="Demo__some-network__share-button"
                      >
                          <TelegramIcon size={32} round />
                      </TelegramShareButton>
                  </div>
                  <div className="Demo__some-network div-left2">
                      <WhatsappShareButton
                          url={shareUrl}
                          title={title}
                          className="Demo__some-network__share-button"
                      >
                          <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                  </div>
                  <div className="Demo__some-network div-left2">
                      <EmailShareButton
                          url={shareUrl}
                          title={title}
                          className="Demo__some-network__share-button"
                      >
                          <EmailIcon size={32} round />
                      </EmailShareButton>
                  </div>

                  <div className="Demo__some-network div-left2">
                      <LineShareButton
                          url={shareUrl}
                          title={title}
                          className="Demo__some-network__share-button"
                      >
                          <LineIcon size={32} round />
                      </LineShareButton>
                  </div>
              </div>
              

        </div> : ""
      }
      {
        
        !disbleBtn ? 
          <Result data={TrackResult} /> 
          : 
          <div className="container div-loading"> Loding...</div>
      }
       
    </div>

  return (
    TRACKING
  );
};
