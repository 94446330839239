import { useTranslation } from 'react-i18next';
export const Contact = (props) => {
    const { t } = useTranslation();

  return (
      <div id='contact'>
        <div className='container'>
          
          <div className='col-md-3  contact-info'>
            <div className='contact-item'>
              <h3>{t('Contact Info')}</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> {props.data ? props.data.companyName : ''} 
                  </span>
                 {props.data ? props.data.address : ''} 
              </p>
            </div>
          </div>
        </div>
      </div>
      
  )
}
